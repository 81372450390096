<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="plan_category" :translate-params="{id: category.id, name: category.name.en}">Edit Base Plan
        #%{id} -
        %{name}
      </translate>
    </h1>
    <Form :data="category" @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/region/category/Form.vue";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'EditPlanCategory',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    this.setRegionId(this.$route.params.region)
    try {
      await this.fetchCategory(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'plan_category.list', params: {region: this.regionId}})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('region', [
      'category',
      'regionId'
    ]),
  },
  methods: {
    ...mapActions('region', [
      'setRegionId',
      'fetchCategory',
      'updateCategory',
    ]),
    async onSave(data) {
      await this.updateCategory({id: this.category.id, data: data})
      await this.$router.push({name: 'plan_category.list', params: {region: this.regionId}})
      throw new SuccessNotification(this.$pgettext('plan_category', 'Category updated!'))
    }
  }
}
</script>